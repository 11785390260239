<template>
  <div class="measurement-all-container">
    <a-table
      :columns="tableColumns"
      :data-source="measurementDataAll"
      class="measurement-all-table"
      :pagination="pagination"
      rowKey="fid"
      @change="handleTableChange"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column?.dataIndex === 'operation'">
          <a>
            <a-image
              :preview="false"
              :width="18"
              src="/img/peroidMeasurementsIcon.png"
              @click="goToPeriodMeasurement(record)"
            />
          </a>
          <!-- <a-dropdown>
          <a class="ant-dropdown-link" @click.prevent>
            <BarsOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <router-link
                  :to="{
                    name: 'MeasurementsPeriod',
                    params: {
                      fid: record.fid,
                    },
                    query: {
                      fromDate: fromDate,
                      toDate: toDate,
                      naviType: 'period',
                    },
                  }"
                >
                  {{ $t('HR001.peroidMeasurements') }}
                </router-link>
              </a-menu-item>
              <a-menu-item>
                <router-link
                  :to="{
                    name: 'MeasurementsPeriod',
                    params: {
                      fid: record.fid,
                    },
                    query: {
                      naviType: 'case',
                    },
                  }"
                >
                  {{ $t('HR001.personalMeasurements') }}
                </router-link>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown> -->
        </template>

        <template v-if="column?.dataIndex === 'times'">
          <span class="item-count-font">
            {{ record?.times ? record?.times : '--' }}
          </span>
        </template>

        <template v-if="column?.dataIndex === 'systolicWarningLevel'">
          <span
            class="item-value-font"
            :class="{
              'normal-color':
                record?.detail?.bloodPressure?.systolicWarningLevel == 0,
              'warning-color':
                record?.detail?.bloodPressure?.systolicWarningLevel == 1,
              'abnormal-color':
                record?.detail?.bloodPressure?.systolicWarningLevel == 2,
            }"
          >
            {{ getSystolicAVG(record) }}
          </span>
        </template>

        <template v-if="column?.dataIndex === 'diastolicWarningLevel'">
          <span
            class="item-value-font"
            :class="{
              'normal-color':
                record?.detail?.bloodPressure?.diastolicWarningLevel == 0,
              'warning-color':
                record?.detail?.bloodPressure?.diastolicWarningLevel == 1,
              'abnormal-color':
                record?.detail?.bloodPressure?.diastolicWarningLevel == 2,
            }"
          >
            {{ getDiastolicAVG(record) }}
          </span>
        </template>

        <template v-if="column?.dataIndex === 'fastingLevel'">
          <span
            class="item-value-font"
            :class="{
              'normal-color': record?.detail?.glucose?.fastingWarningLevel == 0,
              'warning-color':
                record?.detail?.glucose?.fastingWarningLevel == 1,
              'abnormal-color':
                record?.detail?.glucose?.fastingWarningLevel == 2,
            }"
          >
            {{ getFastingAVG(record) }}
          </span>
        </template>

        <template v-if="column?.dataIndex === 'beforeMealLevel'">
          <span
            class="item-value-font"
            :class="{
              'normal-color':
                record?.detail?.glucose?.beforeMealWarningLevel == 0,
              'warning-color':
                record?.detail?.glucose?.beforeMealWarningLevel == 1,
              'abnormal-color':
                record?.detail?.glucose?.beforeMealWarningLevel == 2,
            }"
          >
            {{ getBeforeMealAVG(record) }}
          </span>
        </template>

        <template v-if="column?.dataIndex === 'afterMealLevel'">
          <span
            class="item-value-font"
            :class="{
              'normal-color':
                record?.detail?.glucose?.afterMealWarningLevel == 0,
              'warning-color':
                record?.detail?.glucose?.afterMealWarningLevel == 1,
              'abnormal-color':
                record?.detail?.glucose?.afterMealWarningLevel == 2,
            }"
          >
            {{ getAfterMealAVG(record) }}
          </span>
        </template>

        <template v-if="column?.dataIndex === 'bodyWeight'">
          <span class="item-value-font">
            {{
              record?.detail?.bodyWeight?.value
                ? roundDec(record?.detail?.bodyWeight?.value, 2)
                : '--'
            }}
          </span>
        </template>

        <template v-if="column?.dataIndex === 'bodyFat'">
          <span
            class="item-value-font"
            :class="{
              'normal-color':
                record?.detail?.bodyWeight?.bodyFatWarningLevel == 0,
              'warning-color':
                record?.detail?.bodyWeight?.bodyFatWarningLevel == 1,
              'abnormal-color':
                record?.detail?.bodyWeight?.bodyFatWarningLevel == 2,
            }"
          >
            {{
              record?.detail?.bodyWeight?.bodyFat
                ? roundDec(record?.detail?.bodyWeight?.bodyFat, 1)
                : '--'
            }}
          </span>
        </template>

        <template v-if="column?.dataIndex === 'visceralFat'">
          <span
            class="item-value-font"
            :class="{
              'normal-color':
                record?.detail?.bodyWeight?.visceralFatWarningLevel == 0,
              'warning-color':
                record?.detail?.bodyWeight?.visceralFatWarningLevel == 1,
              'abnormal-color':
                record?.detail?.bodyWeight?.visceralFatWarningLevel == 2,
            }"
          >
            {{
              record?.detail?.bodyWeight?.visceralFat
                ? Math.floor(record?.detail?.bodyWeight?.visceralFat)
                : '--'
            }}
          </span>
        </template>

        <!-- <template v-if="column?.dataIndex === 'spo2AbnormalLevel'">
          <span
            class="item-value-font"
            :class="{
              'normal-color': record?.detail?.spo2?.spo2WarningLevel == 0,
              'warning-color': record?.detail?.spo2?.spo2WarningLevel == 1,
              'abnormal-color': record?.detail?.spo2?.spo2WarningLevel == 2,
            }"
          >
            {{
              record?.detail?.spo2?.value
                ? roundDec(record?.detail?.spo2?.value, 0)
                : '--'
            }}
          </span>
        </template> -->

        <template v-if="column?.dataIndex === 'totalCholesterol'">
          <span class="item-value-font normal-color">
            {{
              record?.detail?.bloodLipid?.totalCholesterolAVG
                ? roundDec(record?.detail?.bloodLipid?.totalCholesterolAVG, 1)
                : '--'
            }}
          </span>
        </template>
      </template>

      <template #expandedRowRender="{ record }">
        <div class="table-expand-area">
          <MeasurementsAllTableDetail :detail="record.detail" />
        </div>
      </template>
    </a-table>
  </div>
</template>
<script>
  // import { BarsOutlined } from '@ant-design/icons-vue'
  //import { defineComponent } from 'vue'
  import { mapGetters } from 'vuex'
  import MeasurementsAllTableDetail from './MeasurementsAllTableDetail'
  import { roundDecimal } from '@/utils/pocketKnives'
  export default {
    data() {
      return {
        tableData: [],
        tableColumns: [
          {
            title: this.$t('HR001.name'),
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: this.$t('HR001.times'),
            dataIndex: 'times',
            key: 'times',
          },
          {
            title: this.$t('HR001.systolic'),
            dataIndex: 'systolicWarningLevel',
            key: 'systolicWarningLevel',
          },
          {
            title: this.$t('HR001.diastolic'),
            dataIndex: 'diastolicWarningLevel',
            key: 'diastolicWarningLevel',
          },
          {
            title: this.$t('HR001.fastingTitle'),
            dataIndex: 'fastingLevel',
            key: 'fastingLevel',
          },
          {
            title: this.$t('HR001.beforeMealTitle'),
            dataIndex: 'beforeMealLevel',
            key: 'beforeMealLevel',
          },
          {
            title: this.$t('HR001.afterMealTitle'),
            dataIndex: 'afterMealLevel',
            key: 'afterMealLevel',
          },
          {
            title: this.$t('HR001.bodyWeight'),
            dataIndex: 'bodyWeight',
            key: 'bodyWeight',
          },
          {
            title: this.$t('HR001.bodyFat'),
            dataIndex: 'bodyFat',
            key: 'bodyFat',
          },
          {
            title: this.$t('HR001.visceralFat'),
            dataIndex: 'visceralFat',
            key: 'visceralFat',
          },
          // {
          //   title: this.$t('HR001.spo2'),
          //   dataIndex: 'spo2AbnormalLevel',
          //   key: 'spo2AbnormalLevel',
          // },
          {
            title: this.$t('HR001.totalCholesterol'),
            dataIndex: 'totalCholesterol',
            key: 'totalCholesterol',
          },
          {
            title: '',
            dataIndex: 'operation',
            key: 'operation',
          },
        ],
        pagination: {
          position: 'top',
          current: 1,
          pageSize: 10,
          total: 0,
          showTotal: this.getTotalCountString,
        },
      }
    },
    props: {
      fromDate: {
        type: Number,
        required: true,
        default: 0,
      },
      toDate: {
        type: Number,
        required: true,
        default: 0,
      },
      pageSize: {
        type: Number,
        required: true,
        default: 10,
      },
    },
    components: {
      // BarsOutlined,
      MeasurementsAllTableDetail,
    },
    computed: {
      ...mapGetters({
        measurementDataAll: 'measurement/measurementDataAll',
        measurementDataAllAmount: 'measurement/measurementDataAllAmount',
        measureThreshold: 'measureThreshold/measureThreshold',
      }),
    },
    mounted() {
      this.pagination.pageSize = this.pageSize
    },
    methods: {
      extraBloodPressureInfo(record) {
        let info = '--'
        if (record && record.detail && record.detail.bloodPressure) {
          if (record.detail.bloodPressure.systolic) {
            info = Math.round(record.detail.bloodPressure.systolic) + '/'
          }
          if (record.detail.bloodPressure.diastolic && info === '--') {
            info = '/' + Math.round(record.detail.bloodPressure.diastolic)
          } else if (record.detail.bloodPressure.diastolic) {
            info = info + Math.round(record.detail.bloodPressure.diastolic)
          }
        }
        return info
      },
      // extraGlucoseInfo(record) {
      //   let info = '--'
      //   let total = 0
      //   let totalCount = 0
      //   if (
      //     record &&
      //     record.detail &&
      //     record.detail.glucose &&
      //     record.detail.glucose.fasting
      //   ) {
      //     total += record.detail.glucose.fasting
      //     totalCount++
      //   }
      //   if (
      //     record &&
      //     record.detail &&
      //     record.detail.glucose &&
      //     record.detail.glucose.beforeMeal
      //   ) {
      //     total += record.detail.glucose.beforeMeal
      //     totalCount++
      //   }
      //   if (
      //     record &&
      //     record.detail &&
      //     record.detail.glucose &&
      //     record.detail.glucose.afterMeal
      //   ) {
      //     total += record.detail.glucose.afterMeal
      //     totalCount++
      //   }

      //   if (total > 0 && totalCount > 0) {
      //     info = total / totalCount
      //     info = roundDecimal(info, 1)
      //   }
      //   return info
      // },
      roundDec(val, precision) {
        return roundDecimal(val, precision)
      },
      getTotalCountString(total) {
        return this.$t('HR001.totalCount') + ` ${total}`
      },
      goToPeriodMeasurement(record) {
        this.$router.push({
          name: 'MeasurementsPeriod',
          params: {
            fid: record.fid,
          },
          query: {
            fromDate: this.fromDate,
            toDate: this.toDate,
            naviType: 'period',
          },
        })
      },
      getSystolicAVG(record) {
        let roundDecimalParam = 0
        if (this.measureThreshold?.bloodPressure?.unit === 'kPa') {
          roundDecimalParam = 1
        } else {
          // mmHg
          roundDecimalParam = 0
        }

        return record?.detail?.bloodPressure?.systolic
          ? roundDecimal(
              record?.detail?.bloodPressure?.systolic,
              roundDecimalParam
            )
          : '--'
      },
      getDiastolicAVG(record) {
        let roundDecimalParam = 0
        if (this.measureThreshold?.bloodPressure?.unit === 'kPa') {
          roundDecimalParam = 1
        } else {
          // mmHg
          roundDecimalParam = 0
        }

        return record?.detail?.bloodPressure?.diastolic
          ? roundDecimal(
              record?.detail?.bloodPressure?.diastolic,
              roundDecimalParam
            )
          : '--'
      },
      getFastingAVG(record) {
        let roundDecimalParam = 0
        if (this.measureThreshold?.bloodGlucose?.unit === 'mmol/L') {
          roundDecimalParam = 1
        } else {
          // mmHg
          roundDecimalParam = 0
        }
        return record?.detail?.glucose?.fasting
          ? roundDecimal(record?.detail?.glucose?.fasting, roundDecimalParam)
          : '--'
      },
      getBeforeMealAVG(record) {
        let roundDecimalParam = 0
        if (this.measureThreshold?.bloodGlucose?.unit === 'mmol/L') {
          roundDecimalParam = 1
        } else {
          // mmHg
          roundDecimalParam = 0
        }
        return record?.detail?.glucose?.beforeMeal
          ? roundDecimal(record?.detail?.glucose?.beforeMeal, roundDecimalParam)
          : '--'
      },
      getAfterMealAVG(record) {
        let roundDecimalParam = 0
        if (this.measureThreshold?.bloodGlucose?.unit === 'mmol/L') {
          roundDecimalParam = 1
        } else {
          // mmHg
          roundDecimalParam = 0
        }
        return record?.detail?.glucose?.afterMeal
          ? roundDecimal(record?.detail?.glucose?.afterMeal, roundDecimalParam)
          : '--'
      },
      handleTableChange(pagination, filters, sorter) {
        console.log(`handleTableChange`)
        console.log(pagination, filters, sorter)
        const pager = { ...this.pagination }
        pager.current = pagination.current
        this.pagination = pager
        this.$emit('onPageChange', pagination)
      },
    },
    watch: {
      measurementDataAll: function () {
        this.pagination.total = this.measurementDataAllAmount
        //console.log('== watch measurementDataAll')
        //console.log(this.measurementDataAll)
      },
    },
  }
</script>
<style lang="less" scoped>
  .normal-color {
    color: #575756;
  }
  .warning-color {
    color: #ffb600;
  }
  .abnormal-color {
    color: #ff0000;
  }
  .item-value-font {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    /* identical to box height, or 89% */

    display: flex;
    align-items: center;
    text-align: center;
  }
  .item-count-font {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 16px;
    /* identical to box height, or 89% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Black/Default */

    color: #000000;
  }
  .measurement-all-container {
    background-color: #ffffff;
    height: 503px;
    //position: relative;

    .measurement-all-table {
      .table-level-tag {
        width: 64px;
        height: 24px;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 4px;
      }

      .table-expand-area {
        width: 990px;
        height: 747px;
      }
    }
  }
</style>
